<template>
  <div>
    <b-card :title="$t('Sipariş Özeti')">
      <hr>
      <div
        v-for="item in cart.items"
        :key="item.id"
        class="border-bottom pb-2 pt-2"
      >
        <b-row class="match-height">
          <b-col>
            <h5 class="mb-0 text-primary">
              {{ item.package }}
            </h5>
            <small>1 Yıl</small>
          </b-col>
          <b-col
            cols="auto"
            class="font-weight-bolder font-medium-2"
          >
            {{ item.price | toCurrency }} ₺
            <small class="text-muted">+ %18 {{ $t('KDV') }}</small>
          </b-col>
        </b-row>
      </div>
      <div class="mt-2">
        <b-row class="match-height">
          <b-col>
            <div class="font-weight-bolder">
              {{ $t('Vergiler Toplamı') }}
            </div>
            <small class="text-muted">(%18 {{ $t('KDV') }})</small>
          </b-col>
          <b-col
            cols="auto"
            class="mt-1 font-weight-bolder"
          >
            {{ cart.taxTotal | toCurrency }} ₺
          </b-col>
        </b-row>
        <hr>
        <b-row class="match-height mt-2">
          <b-col>
            <div class="font-weight-bolder">
              {{ $t('Toplam Ödenecek Tutar') }}
            </div>
            <small class="text-muted">({{ $t('KDV Dahil') }})</small>
          </b-col>
          <b-col
            cols="auto"
            class="font-medium-4 font-weight-bolder text-primary"
          >
            {{ cart.total | toCurrency }} ₺
          </b-col>
        </b-row>
        <div class="mt-1">
          <b-button
            variant="primary"
            block
            :disabled="saveOrderStatus.status"
            @click="saveOrder"
          >
            <FeatherIcon icon="ShoppingCartIcon" />
            {{ $t('Siparişi Tamamla') }}
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Totals',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  computed: {
    cart() {
      return this.$store.getters['cart/getCart']
    },
    saveOrderStatus() {
      return this.$store.getters['appOrders/getOrderSaveStatus']
    },
  },
  methods: {
    saveOrder() {
      this.$store.dispatch('appOrders/saveOrderData', this.cart.items)
    },
  },
}
</script>

<style scoped>

</style>

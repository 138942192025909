<template>
  <div>
    <b-row v-if="cart.count">
      <b-col
        v-if="saveOrderStatus.message"
        cols="12"
      >
        <b-alert
          :variant="saveOrderStatus.status === true? 'success' : 'danger'"
          show
        >
          <div class="alert-body text-center">
            <div class="pt-2">
              <FeatherIcon
                icon="CheckCircleIcon"
                size="32"
              />
            </div>
            <div class="mt-2 mb-2 font-medium-2">
              {{ saveOrderStatus.message }}
            </div>
            <div class="pb-2">
              <b-button
                variant="success"
                to="/siparislerim"
              >
                {{ $t('Siparişlerim') }}
              </b-button>
            </div>
          </div>
        </b-alert>
      </b-col>
      <b-col>
        <items v-if="!saveOrderStatus.message" />
        <bank-accounts />
      </b-col>
      <b-col
        v-if="!saveOrderStatus.message"
        xs="12"
        md="4"
      >
        <totals />
      </b-col>
    </b-row>
    <div
      v-else
      class="text-center"
    >
      <b-alert
        variant="info"
        show
      >
        <div class="alert-body">
          <div class="mt-1">
            <FeatherIcon
              icon="InfoIcon"
              size="24"
            />
          </div>
          <div class="font-medium-4">
            {{ $t('Sepetiniz Boş') }}
          </div>
        </div>
      </b-alert>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BAlert, BButton,
} from 'bootstrap-vue'
import Items from '@/views/App/Orders/Checkout/Items.vue'
import Totals from '@/views/App/Orders/Checkout/Totals.vue'
import BankAccounts from '@/views/App/BankAccounts/Index.vue'

export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
    BAlert,
    BButton,
    Items,
    Totals,
    BankAccounts,
  },
  computed: {
    cart() {
      return this.$store.getters['cart/getCart']
    },
    saveOrderStatus() {
      return this.$store.getters['appOrders/getOrderSaveStatus']
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <b-card>
    <b-row
      v-for="item in cart.items"
      :key="item.id"
      class="match-height mt-2 mb-2"
    >
      <b-col
        cols="auto"
      >
        <b-avatar
          :text="item.avatar_text"
          size="64"
          variant="primary"
          class="mt-2"
        />
      </b-col>
      <b-col>
        <h3>{{ item.package }}</h3>
        <div>{{ item.package_content }}</div>
        <small class="text-muted">{{ item.startup }}</small>
        <div class="text-muted mt-1">
          Paket kullanım süresi: {{ item.duration }} Yıl
        </div>
      </b-col>
      <b-col
        cols="auto"
        class="text-center border-left"
      >
        <div class="mb-2">
          <div class="text-primary font-medium-4 font-weight-bolder">
            <div>{{ item.amount | toCurrency }} ₺</div>
          </div>
          <div class="text-muted font-small-2">
            + KDV
          </div>
        </div>
        <div>
          <b-button
            variant="light"
            block
            @click="removeItem(item.id)"
          >
            <FeatherIcon icon="XIcon" />
            Sepetten Çıkar
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BAvatar, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Items',
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BButton,
  },
  computed: {
    cart() {
      return this.$store.getters['cart/getCart']
    },
  },
  methods: {
    removeItem(id) {
      this.$store.dispatch('cart/cartRemoveItem', id)
    },
  },
}
</script>

<style scoped>

</style>
